.App {
    text-align: center;
    width: 100%;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

a:link {
    text-decoration: none;
    color: #282c34;
}

.App-link {
    color: #09d3ac;
}

.top-bar {
    display: inline-block;
    margin-left: 2px;
}

.top-bar-container {
    right: 100px;
    position: absolute;
    z-index: 1300;
    margin-top: 10px;
}

#city-title-button {
    right: 70px !important;
    position: absolute;
}

#city-image {
    width: auto !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.button {
    background-color: transparent;
    border: none;
    float: left;
}

.button-wrapper {
    width: 80px;
}

#image-bar {
    height: 20% !important;
}

.user-profile-title {
    color: antiquewhite;
    font-size: 24px;
    position: relative;
}

.upload-user-profile-pic {
    text-align: center;
    margin-top: 25;
    color: #282c34;
}

.detail-site-icon {
    position: absolute;
    left: -20px;
    top: 0;
}

.place-container {
    width: 80%;
    float: left;
}

.site-icon {
    float: left;
    position: relative;
    margin: 40px;
}

#highlight-image,
#hotel-image,
#attraction-image,
#restaurant-image,
#itinerary-image {
    width: auto !important;
    height: 90% !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.dashboard-table tbody td:nth-child(4) {
    padding: 8px 16px;
    width: 20%;
}

.dashboard-table tbody tr {
    height: 100px;
}

#search-bar {
    width: 100% !important;
    /* height: 10% !important; */
    text-align: left !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: black !important;
    position: absolute !important;
    bottom: 0 !important;
    padding: 0 30px !important;
    font-weight: lighter !important;
}

main button {
    margin: 0 5px !important;
}

.MuiCardActionArea-root {
    margin: 10px 0 !important;
}

.margin-small {
    margin: 25px;
}

.margin-small * {
    padding: 8px !important;
    margin: 5px;
}

.title-container {
    position: relative;
}

.logo img {
    width: 100px;
    height: auto
}

.logo {
    margin: auto;
    width: 100px;
    padding: 10px;
}

.MuiCardActionArea-root {
    margin: 0 !important;
}

.itin-detail-icons * {
    float: left;
}

.dayTripList * {
    padding: 10px;
}